<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <v-toolbar class="mt-2" flat dense :color="toolbarColor">
        <v-btn icon small @click="commands.undo" class="mr-1">
          <v-icon>undo</v-icon>
        </v-btn>
        <v-btn icon small @click="commands.redo" class="mx-1">
          <v-icon>redo</v-icon>
        </v-btn>
        <v-divider vertical inset />
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.bold() }"
          class="mx-1"
          @click="commands.bold"
        >
          <v-icon>
            format_bold
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.italic() }"
          class="mx-1"
          @click="commands.italic"
        >
          <v-icon>
            format_italic
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.strike() }"
          class="mx-1"
          @click="commands.strike"
        >
          <v-icon>
            format_strikethrough
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.underline() }"
          class="mx-1"
          @click="commands.underline"
        >
          <v-icon>
            format_underline
          </v-icon>
        </v-btn>
        <v-divider vertical inset />
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.paragraph() }"
          class="mx-1"
          @click="commands.paragraph"
        >
          P
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.heading({ level: 1 }) }"
          class="mx-1"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.heading({ level: 2 }) }"
          class="mx-1"
          @click="commands.heading({ level: 2 })"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          H2
        </v-btn>
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.heading({ level: 3 }) }"
          class="mx-1"
          @click="commands.heading({ level: 3 })"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          H3
        </v-btn>
        <v-divider vertical inset />
        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.bullet_list() }"
          class="mx-1"
          @click="commands.bullet_list"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-icon>
            format_list_bulleted
          </v-icon>
        </v-btn>

        <v-btn
          icon
          small
          :class="{ 'v-btn--active': isActive.ordered_list() }"
          class="mx-1"
          @click="commands.ordered_list"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-icon>
            format_list_numbered
          </v-icon>
        </v-btn>
        <v-menu
          v-model="dropDownMenuActive"
          offset-y
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <template v-slot:activator="{ on }">
            <v-btn small class="mx-1" icon v-on="on">
              <v-icon
                :style="[
                  dropDownMenuActive ? { transform: 'rotate(-180deg)' } : {}
                ]"
              >
                arrow_drop_down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                icon
                small
                :class="{
                  'v-btn--active': isActive.heading({ level: 2 })
                }"
                class="mx-1"
                @click="commands.heading({ level: 2 })"
              >
                H2
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                icon
                small
                :class="{
                  'v-btn--active': isActive.heading({ level: 3 })
                }"
                class="mx-1"
                @click="commands.heading({ level: 3 })"
              >
                H3
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                icon
                small
                :class="{ 'v-btn--active': isActive.bullet_list() }"
                class="mx-1"
                @click="commands.bullet_list"
              >
                <v-icon>
                  format_list_bulleted
                </v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                icon
                small
                :class="{ 'v-btn--active': isActive.ordered_list() }"
                class="mx-1"
                @click="commands.ordered_list"
              >
                <v-icon>
                  format_list_numbered
                </v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </editor-menu-bar>
    <editor-menu-bubble
      class="menububble"
      :editor="editor"
      @hide="hideLinkMenu"
      v-slot="{ commands, isActive, getMarkAttrs, menu }"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <form
          class="menububble__form"
          v-if="linkMenuIsActive"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            class="menububble__input"
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            ref="linkInput"
            @keydown.esc="hideLinkMenu"
          />
          <button
            class="menububble__button"
            @click="setLinkUrl(commands.link, null)"
            type="button"
          >
            <v-icon color="white">
              clear
            </v-icon>
          </button>
        </form>

        <template v-else>
          <button
            class="menububble__button"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
          >
            <span>{{ isActive.link() ? "Update Link" : "Add Link" }}</span>
            <v-icon color="white">
              link
            </v-icon>
          </button>
        </template>
      </div>
    </editor-menu-bubble>
    <editor-content class="editor__content mt-4 ml-1" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from "tiptap";
import {
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  name: "TextEditor",
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ]
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      dropDownMenuActive: false
    };
  },
  computed: {
    toolbarColor() {
      if (this.$store.state.settings.darkMode) {
        return "primary";
      } else {
        return "grey lighten-4";
      }
    }
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    reset() {
      this.editor.clearContent();
    },
    getHTML() {
      return this.editor.getHTML();
    },
    setContent(content) {
      this.editor.setContent(content);
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style>
.ProseMirror {
  outline: none;
  min-height: 175px;
}
.menububble {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button {
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff;
}

.menububble__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
